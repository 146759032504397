import RednessLocationsTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-redness/redness-locations/RednessLocationsTemplate';
import { FACE_ZONE, GENDER } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/Redness/RednessLocations/RednessLocationsTemplate'
};

const createStory = props => () => ({
  components: { RednessLocationsTemplate },
  storyProps: props,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template: `<div :style='$options.wrapStyles'>
    <redness-locations-template v-bind="$options.storyProps" />
  </div>`
});

export const Base = createStory({
  gender: GENDER.FEMALE,
  rednessLimitedToPimples: 'yes',
  rednessLocations: [FACE_ZONE.FOREHEAD, FACE_ZONE.NOSE, FACE_ZONE.CHEEKS_AND_CHEEKBONES]
});
